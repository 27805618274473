.dark {
    .tip-item {
        background: #1f2937;
        border-color: #374151;
        &.active {
            border-color: #1f2937;
            background: #111827;
        }
    }
}

.tip-item {
    box-shadow: 0 0 0 1px #4882f533;
    &.active {
      box-shadow: 0 0 0 2px #4883f5;
    }
}


ion-list {
    &[lines='none'] {
        border: none !important;
    }
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

ion-item.item-border {
    --border-radius: 10px;
    --border-width: 1px;
}
ion-tabs {
    ion-tab-button {
        transform: scale(0.7);
        ion-icon {
            margin: 3px 0 0;
        }
    }
    .tab-selected {
        transform: scale(0.9);
        transition: transform 0.1s ease-in-out;
    }
}
html body.swal2-height-auto {
    height: 100vh !important;
}

ion-button,
button {
    text-transform: none !important;
    &.bold {
        font-weight: bold !important;
    }
}
ion-item {
    ion-label {
        h2 {
            white-space: pre-wrap;
        }
    }
    ion-button[slot='end'] {
        margin-left: 0;
    }
}

ion-card {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

.loading-item {
    position: relative;
    .no-items {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        backdrop-filter: blur(3px);
        // background: rgba(255, 255, 255, 0.6%);
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}

.overlay {
    z-index: 10;
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 80vh;
    background-image: linear-gradient(
        to bottom,
        hsla(0, 0%, 0%, 0) 0%,
        hsla(0, 0%, 0%, 0.013) 8.1%,
        hsla(0, 0%, 0%, 0.049) 15.5%,
        hsla(0, 0%, 0%, 0.104) 22.5%,
        hsla(0, 0%, 0%, 0.175) 29%,
        hsla(0, 0%, 0%, 0.259) 35.3%,
        hsla(0, 0%, 0%, 0.352) 41.2%,
        hsla(0, 0%, 0%, 0.45) 47.1%,
        hsla(0, 0%, 0%, 0.55) 52.9%,
        hsla(0, 0%, 0%, 0.648) 58.8%,
        hsla(0, 0%, 0%, 0.741) 64.7%,
        hsla(0, 0%, 0%, 0.825) 71%,
        hsla(0, 0%, 0%, 0.896) 77.5%,
        hsla(0, 0%, 0%, 0.951) 84.5%,
        hsla(0, 0%, 0%, 0.987) 91.9%,
        hsl(0, 0%, 0%) 100%
    );
}

body,
html {
    background: var(--bg-color);
}

ion-refresher {
    z-index: 1;
}

ion-skeleton-text {
    border-radius: 5px;
}
/*
ion-header {
  ion-title {
    line-height: 40px;
  }
  ion-toolbar[color='paystory-dark'] {
    background: var(--ion-color-paystory-dark);
    ion-title {
      color: #fff !important;
    }
  }
}

ion-title {
  color: #fff !important;
  --color: #fff !important;
}
*/
.ios {
    ion-title.title-logo {
        display: flex;
        align-items: center;
        img {
            height: 23px;
            &.center {
                margin: 0 auto;
            }
        }
    }
}
.md {
    ion-title.title-logo {
        img {
            height: 23px;
        }
    }
}

ion-button {
    &.xs {
        font-size: 10px;
    }
}
.list-ios {
    ion-item {
        --padding-start: 10px;
    }
}
/*
.md {
  ion-app {
    ion-header {
      ion-toolbar:first-of-type {
        padding-top: 25px;
      }
    }
  }
}*/
.list-md {
    padding-top: 0;
    padding-bottom: 0;
}

/*
//-- Validation failed
ion-item.ion-invalid.ion-touched {
  ion-input {
    border-bottom: 2px solid var(--ion-color-danger) !important;
  }
}
//-- Validation passed
ion-item.ion-valid.ion-touched {
  ion-input {
    border-bottom: 2px solid var(--ion-color-success) !important;
  }
}
*/
/*
.header-ios ion-toolbar:last-of-type {
  --border-color: var(--ion-color-primary) !important;
}

ion-modal {
  .modal-wrapper {
    background: transparent !important;
  }
}
*/
ion-item.logout {
    ion-icon {
        color: #dc3545 !important;
    }
    ion-label {
        color: #dc3545 !important;
    }
}

.bg_color {
    --background: var(--bg-color);
}

.d-flex {
    display: flex !important;
    align-items: center;
    width: 100%;

    .end {
        margin-left: auto !important;
        margin-right: 0 !important;
        display: block;
    }
}
.badge {
    @apply border-0;
}
