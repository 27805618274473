/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'animate.css/animate.css';

@import 'variables.scss';
@import 'custom.scss';
// @import './fonts/google-sans.scss';
@import './card/card.css';

@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';

.left-image {
    border: 1px solid #6b7280;
    box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.5);
    background-color: #484848;
}

.avatar-image {
    display: block;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 60px;
    width: 60px;
}

ion-modal {
    --background: transparent;
}
.swal2-styled.swal2-confirm {
    background-color: var(--primary) !important;
}
.swal2-styled.swal2-confirm:focus {
    box-shadow: 0 0 0 3px rgb(150 186 215) !important;
}

:focus {
    outline: none;
}

.ng-lazyloaded {
    animation: fadein 0.4s;
}
@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.set-location-sheet .action-sheet-button#new-address-btn,
.set-location-sheet .action-sheet-button#new-address-btn .action-sheet-icon {
    color: var(--ion-color-primary);
}

ion-list-header {
    @apply font-semibold text-left text-xs  uppercase tracking-wider leading-none pl-0 ml-5;
}
.list-header {
    @apply font-semibold text-left text-xs  uppercase tracking-wider leading-none mb-3 mt-5;
}

.md {
    ion-loading {
        // top: 56px;
    }
}
.ios {
    ion-loading {
        // top: 44px;
    }
    ion-list-header {
        @apply mb-3;
    }
}
